<template>
  <div class="bet-logo" :class="{ 'has-link': !!urlLink }" :style="{ width, height }" @click="onClick">
    <img v-if="urlLogo" :src="urlLogo" alt="" :style="{ width, height }" />
  </div>
</template>

<script>
export default {
  name: 'BetLogo',
  props: {
    // css width
    width: {
      type: String,
      default: '10.5em',
    },
    height: {
      type: String,
      default: 'auto',
    },
    urlLink: {
      type: String,
      default: '',
    },
    urlLogo: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClick() {
      if (this.urlLink) {
        window.open(this.urlLink);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bet-logo {
  font-size: 1rem;

  & > img {
    object-fit: contain;
  }
}

.has-link {
  cursor: pointer;
}
</style>
